import React from 'react';
import { withStyles } from '@material-ui/core';
import {
  Route, Switch,
} from 'react-router-dom';
import history from './history';
import Assessment from './pages/Assessment';
import Home from './pages/Home';
import CreateStore from './store';

const styles = (theme) => ({
  // Load app bar information from the theme
  toolbar: theme.mixins.toolbar,
});

const App = ({ classes }) => {
  const store = CreateStore();

  const onCreate = async ({ name, description }) => {
    try {
      const resp = await store.createAssessment(name, description);
      // eslint-disable-next-line no-console
      console.log('Got resp', resp);
      history.push(`/a/${resp.slug}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err', err);
    }
  };

  return (
    <>
      {/* Padding for the App Bar */}
      <div className={classes.toolbar} />

      <Switch>
        <Route exact path="/">
          <Home
            onCreate={onCreate}
            getLatest={store.getLatestAssessments}
          />
        </Route>
        <Route path="/a/:id">
          {(props) => <Assessment id={props.match.params.id} />}
        </Route>
      </Switch>

    </>
  );
};

export default withStyles(styles)(App);
