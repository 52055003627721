/**
 * @fileoverview gRPC-Web generated client stub for riskmanager.api.risks.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var assessments_assessments_pb = require('../assessments/assessments_pb.js')
const proto = {};
proto.riskmanager = {};
proto.riskmanager.api = {};
proto.riskmanager.api.risks = {};
proto.riskmanager.api.risks.v1 = require('./risks_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.riskmanager.api.risks.v1.RiskServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.riskmanager.api.risks.v1.RiskServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.risks.v1.RiskSaveRequest,
 *   !proto.riskmanager.api.risks.v1.RiskSaveReply>}
 */
const methodDescriptor_RiskService_Save = new grpc.web.MethodDescriptor(
  '/riskmanager.api.risks.v1.RiskService/Save',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.risks.v1.RiskSaveRequest,
  proto.riskmanager.api.risks.v1.RiskSaveReply,
  /**
   * @param {!proto.riskmanager.api.risks.v1.RiskSaveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.risks.v1.RiskSaveReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskSaveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.risks.v1.RiskSaveReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.risks.v1.RiskSaveReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.risks.v1.RiskServiceClient.prototype.save =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/Save',
      request,
      metadata || {},
      methodDescriptor_RiskService_Save,
      callback);
};


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskSaveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.risks.v1.RiskSaveReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.risks.v1.RiskServicePromiseClient.prototype.save =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/Save',
      request,
      metadata || {},
      methodDescriptor_RiskService_Save);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.risks.v1.RiskGetRequest,
 *   !proto.riskmanager.api.risks.v1.RiskGetReply>}
 */
const methodDescriptor_RiskService_Get = new grpc.web.MethodDescriptor(
  '/riskmanager.api.risks.v1.RiskService/Get',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.risks.v1.RiskGetRequest,
  proto.riskmanager.api.risks.v1.RiskGetReply,
  /**
   * @param {!proto.riskmanager.api.risks.v1.RiskGetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.risks.v1.RiskGetReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskGetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.risks.v1.RiskGetReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.risks.v1.RiskGetReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.risks.v1.RiskServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/Get',
      request,
      metadata || {},
      methodDescriptor_RiskService_Get,
      callback);
};


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskGetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.risks.v1.RiskGetReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.risks.v1.RiskServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/Get',
      request,
      metadata || {},
      methodDescriptor_RiskService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest,
 *   !proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply>}
 */
const methodDescriptor_RiskService_GetFromAssessment = new grpc.web.MethodDescriptor(
  '/riskmanager.api.risks.v1.RiskService/GetFromAssessment',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest,
  proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply,
  /**
   * @param {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.risks.v1.RiskServiceClient.prototype.getFromAssessment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/GetFromAssessment',
      request,
      metadata || {},
      methodDescriptor_RiskService_GetFromAssessment,
      callback);
};


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.risks.v1.RiskServicePromiseClient.prototype.getFromAssessment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/GetFromAssessment',
      request,
      metadata || {},
      methodDescriptor_RiskService_GetFromAssessment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.risks.v1.RiskGetLatestRequest,
 *   !proto.riskmanager.api.risks.v1.RiskGetLatestReply>}
 */
const methodDescriptor_RiskService_GetLatest = new grpc.web.MethodDescriptor(
  '/riskmanager.api.risks.v1.RiskService/GetLatest',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.risks.v1.RiskGetLatestRequest,
  proto.riskmanager.api.risks.v1.RiskGetLatestReply,
  /**
   * @param {!proto.riskmanager.api.risks.v1.RiskGetLatestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.risks.v1.RiskGetLatestReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskGetLatestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.risks.v1.RiskGetLatestReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.risks.v1.RiskGetLatestReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.risks.v1.RiskServiceClient.prototype.getLatest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/GetLatest',
      request,
      metadata || {},
      methodDescriptor_RiskService_GetLatest,
      callback);
};


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskGetLatestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.risks.v1.RiskGetLatestReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.risks.v1.RiskServicePromiseClient.prototype.getLatest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/GetLatest',
      request,
      metadata || {},
      methodDescriptor_RiskService_GetLatest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.risks.v1.RiskDeleteRequest,
 *   !proto.riskmanager.api.risks.v1.RiskDeleteReply>}
 */
const methodDescriptor_RiskService_Delete = new grpc.web.MethodDescriptor(
  '/riskmanager.api.risks.v1.RiskService/Delete',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.risks.v1.RiskDeleteRequest,
  proto.riskmanager.api.risks.v1.RiskDeleteReply,
  /**
   * @param {!proto.riskmanager.api.risks.v1.RiskDeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.risks.v1.RiskDeleteReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.risks.v1.RiskDeleteReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.risks.v1.RiskDeleteReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.risks.v1.RiskServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/Delete',
      request,
      metadata || {},
      methodDescriptor_RiskService_Delete,
      callback);
};


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.risks.v1.RiskDeleteReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.risks.v1.RiskServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/Delete',
      request,
      metadata || {},
      methodDescriptor_RiskService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.risks.v1.RiskSearchRequest,
 *   !proto.riskmanager.api.risks.v1.RiskSearchReply>}
 */
const methodDescriptor_RiskService_Search = new grpc.web.MethodDescriptor(
  '/riskmanager.api.risks.v1.RiskService/Search',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.risks.v1.RiskSearchRequest,
  proto.riskmanager.api.risks.v1.RiskSearchReply,
  /**
   * @param {!proto.riskmanager.api.risks.v1.RiskSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.risks.v1.RiskSearchReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.risks.v1.RiskSearchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.risks.v1.RiskSearchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.risks.v1.RiskServiceClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/Search',
      request,
      metadata || {},
      methodDescriptor_RiskService_Search,
      callback);
};


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.risks.v1.RiskSearchReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.risks.v1.RiskServicePromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.risks.v1.RiskService/Search',
      request,
      metadata || {},
      methodDescriptor_RiskService_Search);
};


module.exports = proto.riskmanager.api.risks.v1;

