import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { assessmentStates } from '../../../store';

const Title = ({
  title,
  description,
  onChange,
  createdOn,
  state,
  updatedOn,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(title);
  const [dialogDescription, setDialogDescription] = useState(description);
  const [dialogState, setDialogState] = useState(state);
  return (
    <>
      <Box sx={{
        my: 3,
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        >
          <Typography variant="h4">
            {title || 'Untitled Assessment'}
          </Typography>
          <Button
            onClick={() => {
              setDialogTitle(title);
              setDialogDescription(description);
              setDialogVisible(true);
            }}
          >
            <Edit color="disabled" size="small" />
          </Button>
        </Box>
        <Typography variant="subtitle1">
          {description || 'No description'}
        </Typography>
        <Typography>
          Created on:
          {' '}
          {createdOn.format('L')}
        </Typography>
        {!!(createdOn - updatedOn) && (
          <Typography>
            Updated on:
            {' '}
            {updatedOn.format('L')}
          </Typography>
        )}
        <Typography>
          State:
          {' '}
          {assessmentStates[state]}
        </Typography>
      </Box>
      <Dialog open={dialogVisible} onClose={() => setDialogVisible(false)}>
        <DialogTitle>Edit Assessment</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <FormControl>
                <TextField
                  label="Title"
                  value={dialogTitle}
                  onChange={(event) => {
                    setDialogTitle(event.target.value);
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl>
                <TextField
                  label="Description"
                  value={dialogDescription}
                  onChange={(event) => {
                    setDialogDescription(event.target.value);
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <InputLabel id="edit-assessment-dialog-state">State</InputLabel>
                <Select
                  labelId="edit-assessment-dialog-state"
                  value={dialogState}
                  onChange={(event) => {
                    setDialogState(event.target.value);
                  }}
                >
                  <MenuItem value={0}>{assessmentStates[0]}</MenuItem>
                  <MenuItem value={1}>{assessmentStates[1]}</MenuItem>
                  <MenuItem value={2}>{assessmentStates[2]}</MenuItem>
                  <MenuItem value={3}>{assessmentStates[3]}</MenuItem>
                  <MenuItem value={4}>{assessmentStates[4]}</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setDialogVisible(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              onChange(dialogTitle, dialogDescription, dialogState);
              setDialogVisible(false);
            }}
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Title;
