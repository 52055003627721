import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = () => (
  <div style={{
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    <CircularProgress />
  </div>
);

export default Loading;
