// source: risks/risks.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var assessments_assessments_pb = require('../assessments/assessments_pb.js');
goog.object.extend(proto, assessments_assessments_pb);
goog.exportSymbol('proto.riskmanager.api.risks.v1.Risk', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskDeleteReply', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskDeleteRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskGetLatestReply', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskGetLatestRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskGetReply', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskGetRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskSaveReply', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskSaveRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskSearchHit', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskSearchReply', null, global);
goog.exportSymbol('proto.riskmanager.api.risks.v1.RiskSearchRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.Risk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.Risk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.Risk.displayName = 'proto.riskmanager.api.risks.v1.Risk';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskSaveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskSaveRequest.displayName = 'proto.riskmanager.api.risks.v1.RiskSaveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskSaveReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskSaveReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskSaveReply.displayName = 'proto.riskmanager.api.risks.v1.RiskSaveReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskGetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskGetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskGetRequest.displayName = 'proto.riskmanager.api.risks.v1.RiskGetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskGetReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskGetReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskGetReply.displayName = 'proto.riskmanager.api.risks.v1.RiskGetReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.displayName = 'proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.repeatedFields_, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.displayName = 'proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskGetLatestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskGetLatestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskGetLatestRequest.displayName = 'proto.riskmanager.api.risks.v1.RiskGetLatestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.riskmanager.api.risks.v1.RiskGetLatestReply.repeatedFields_, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskGetLatestReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskGetLatestReply.displayName = 'proto.riskmanager.api.risks.v1.RiskGetLatestReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskDeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskDeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskDeleteRequest.displayName = 'proto.riskmanager.api.risks.v1.RiskDeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskDeleteReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskDeleteReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskDeleteReply.displayName = 'proto.riskmanager.api.risks.v1.RiskDeleteReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskSearchRequest.displayName = 'proto.riskmanager.api.risks.v1.RiskSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskSearchReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.riskmanager.api.risks.v1.RiskSearchReply.repeatedFields_, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskSearchReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskSearchReply.displayName = 'proto.riskmanager.api.risks.v1.RiskSearchReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.risks.v1.RiskSearchHit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.risks.v1.RiskSearchHit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.risks.v1.RiskSearchHit.displayName = 'proto.riskmanager.api.risks.v1.RiskSearchHit';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.Risk.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.Risk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.Risk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.Risk.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    probability: jspb.Message.getFieldWithDefault(msg, 4, 0),
    impact: jspb.Message.getFieldWithDefault(msg, 5, 0),
    created: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updated: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.Risk}
 */
proto.riskmanager.api.risks.v1.Risk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.Risk;
  return proto.riskmanager.api.risks.v1.Risk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.Risk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.Risk}
 */
proto.riskmanager.api.risks.v1.Risk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProbability(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setImpact(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreated(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.Risk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.Risk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.Risk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.Risk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProbability();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getImpact();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreated();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdated();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.Risk.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.Risk} returns this
 */
proto.riskmanager.api.risks.v1.Risk.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.Risk.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.Risk} returns this
 */
proto.riskmanager.api.risks.v1.Risk.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.Risk.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.Risk} returns this
 */
proto.riskmanager.api.risks.v1.Risk.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 probability = 4;
 * @return {number}
 */
proto.riskmanager.api.risks.v1.Risk.prototype.getProbability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.riskmanager.api.risks.v1.Risk} returns this
 */
proto.riskmanager.api.risks.v1.Risk.prototype.setProbability = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 impact = 5;
 * @return {number}
 */
proto.riskmanager.api.risks.v1.Risk.prototype.getImpact = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.riskmanager.api.risks.v1.Risk} returns this
 */
proto.riskmanager.api.risks.v1.Risk.prototype.setImpact = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string created = 6;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.Risk.prototype.getCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.Risk} returns this
 */
proto.riskmanager.api.risks.v1.Risk.prototype.setCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string updated = 7;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.Risk.prototype.getUpdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.Risk} returns this
 */
proto.riskmanager.api.risks.v1.Risk.prototype.setUpdated = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskSaveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskSaveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    risk: (f = msg.getRisk()) && proto.riskmanager.api.risks.v1.Risk.toObject(includeInstance, f),
    assessmentSlug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskSaveRequest}
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskSaveRequest;
  return proto.riskmanager.api.risks.v1.RiskSaveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskSaveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskSaveRequest}
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.risks.v1.Risk;
      reader.readMessage(value,proto.riskmanager.api.risks.v1.Risk.deserializeBinaryFromReader);
      msg.setRisk(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssessmentSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskSaveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskSaveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRisk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.riskmanager.api.risks.v1.Risk.serializeBinaryToWriter
    );
  }
  f = message.getAssessmentSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Risk risk = 1;
 * @return {?proto.riskmanager.api.risks.v1.Risk}
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.prototype.getRisk = function() {
  return /** @type{?proto.riskmanager.api.risks.v1.Risk} */ (
    jspb.Message.getWrapperField(this, proto.riskmanager.api.risks.v1.Risk, 1));
};


/**
 * @param {?proto.riskmanager.api.risks.v1.Risk|undefined} value
 * @return {!proto.riskmanager.api.risks.v1.RiskSaveRequest} returns this
*/
proto.riskmanager.api.risks.v1.RiskSaveRequest.prototype.setRisk = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.riskmanager.api.risks.v1.RiskSaveRequest} returns this
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.prototype.clearRisk = function() {
  return this.setRisk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.prototype.hasRisk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string assessment_slug = 2;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.prototype.getAssessmentSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.RiskSaveRequest} returns this
 */
proto.riskmanager.api.risks.v1.RiskSaveRequest.prototype.setAssessmentSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskSaveReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskSaveReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskSaveReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSaveReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    risk: (f = msg.getRisk()) && proto.riskmanager.api.risks.v1.Risk.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskSaveReply}
 */
proto.riskmanager.api.risks.v1.RiskSaveReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskSaveReply;
  return proto.riskmanager.api.risks.v1.RiskSaveReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskSaveReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskSaveReply}
 */
proto.riskmanager.api.risks.v1.RiskSaveReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.risks.v1.Risk;
      reader.readMessage(value,proto.riskmanager.api.risks.v1.Risk.deserializeBinaryFromReader);
      msg.setRisk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskSaveReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskSaveReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskSaveReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSaveReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRisk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.riskmanager.api.risks.v1.Risk.serializeBinaryToWriter
    );
  }
};


/**
 * optional Risk risk = 1;
 * @return {?proto.riskmanager.api.risks.v1.Risk}
 */
proto.riskmanager.api.risks.v1.RiskSaveReply.prototype.getRisk = function() {
  return /** @type{?proto.riskmanager.api.risks.v1.Risk} */ (
    jspb.Message.getWrapperField(this, proto.riskmanager.api.risks.v1.Risk, 1));
};


/**
 * @param {?proto.riskmanager.api.risks.v1.Risk|undefined} value
 * @return {!proto.riskmanager.api.risks.v1.RiskSaveReply} returns this
*/
proto.riskmanager.api.risks.v1.RiskSaveReply.prototype.setRisk = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.riskmanager.api.risks.v1.RiskSaveReply} returns this
 */
proto.riskmanager.api.risks.v1.RiskSaveReply.prototype.clearRisk = function() {
  return this.setRisk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.riskmanager.api.risks.v1.RiskSaveReply.prototype.hasRisk = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskGetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskGetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskGetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetRequest}
 */
proto.riskmanager.api.risks.v1.RiskGetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskGetRequest;
  return proto.riskmanager.api.risks.v1.RiskGetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetRequest}
 */
proto.riskmanager.api.risks.v1.RiskGetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskGetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskGetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string risk_id = 1;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.RiskGetRequest.prototype.getRiskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.RiskGetRequest} returns this
 */
proto.riskmanager.api.risks.v1.RiskGetRequest.prototype.setRiskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskGetReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskGetReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    risk: (f = msg.getRisk()) && proto.riskmanager.api.risks.v1.Risk.toObject(includeInstance, f),
    assessmentSlug: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assessmentName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetReply}
 */
proto.riskmanager.api.risks.v1.RiskGetReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskGetReply;
  return proto.riskmanager.api.risks.v1.RiskGetReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetReply}
 */
proto.riskmanager.api.risks.v1.RiskGetReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.risks.v1.Risk;
      reader.readMessage(value,proto.riskmanager.api.risks.v1.Risk.deserializeBinaryFromReader);
      msg.setRisk(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssessmentSlug(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssessmentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskGetReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRisk();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.riskmanager.api.risks.v1.Risk.serializeBinaryToWriter
    );
  }
  f = message.getAssessmentSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssessmentName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Risk risk = 1;
 * @return {?proto.riskmanager.api.risks.v1.Risk}
 */
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.getRisk = function() {
  return /** @type{?proto.riskmanager.api.risks.v1.Risk} */ (
    jspb.Message.getWrapperField(this, proto.riskmanager.api.risks.v1.Risk, 1));
};


/**
 * @param {?proto.riskmanager.api.risks.v1.Risk|undefined} value
 * @return {!proto.riskmanager.api.risks.v1.RiskGetReply} returns this
*/
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.setRisk = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetReply} returns this
 */
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.clearRisk = function() {
  return this.setRisk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.hasRisk = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string assessment_slug = 2;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.getAssessmentSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.RiskGetReply} returns this
 */
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.setAssessmentSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assessment_name = 3;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.getAssessmentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.RiskGetReply} returns this
 */
proto.riskmanager.api.risks.v1.RiskGetReply.prototype.setAssessmentName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessmentSlug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest;
  return proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssessmentSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessmentSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assessment_slug = 1;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.prototype.getAssessmentSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest} returns this
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentRequest.prototype.setAssessmentSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessment: (f = msg.getAssessment()) && assessments_assessments_pb.Assessment.toObject(includeInstance, f),
    risksList: jspb.Message.toObjectList(msg.getRisksList(),
    proto.riskmanager.api.risks.v1.Risk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply;
  return proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new assessments_assessments_pb.Assessment;
      reader.readMessage(value,assessments_assessments_pb.Assessment.deserializeBinaryFromReader);
      msg.setAssessment(value);
      break;
    case 2:
      var value = new proto.riskmanager.api.risks.v1.Risk;
      reader.readMessage(value,proto.riskmanager.api.risks.v1.Risk.deserializeBinaryFromReader);
      msg.addRisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      assessments_assessments_pb.Assessment.serializeBinaryToWriter
    );
  }
  f = message.getRisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.riskmanager.api.risks.v1.Risk.serializeBinaryToWriter
    );
  }
};


/**
 * optional riskmanager.api.assessments.v1.Assessment assessment = 1;
 * @return {?proto.riskmanager.api.assessments.v1.Assessment}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.getAssessment = function() {
  return /** @type{?proto.riskmanager.api.assessments.v1.Assessment} */ (
    jspb.Message.getWrapperField(this, assessments_assessments_pb.Assessment, 1));
};


/**
 * @param {?proto.riskmanager.api.assessments.v1.Assessment|undefined} value
 * @return {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply} returns this
*/
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.setAssessment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply} returns this
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.clearAssessment = function() {
  return this.setAssessment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.hasAssessment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Risk risks = 2;
 * @return {!Array<!proto.riskmanager.api.risks.v1.Risk>}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.getRisksList = function() {
  return /** @type{!Array<!proto.riskmanager.api.risks.v1.Risk>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.riskmanager.api.risks.v1.Risk, 2));
};


/**
 * @param {!Array<!proto.riskmanager.api.risks.v1.Risk>} value
 * @return {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply} returns this
*/
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.setRisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.riskmanager.api.risks.v1.Risk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.riskmanager.api.risks.v1.Risk}
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.addRisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.riskmanager.api.risks.v1.Risk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply} returns this
 */
proto.riskmanager.api.risks.v1.RiskGetAllAssessmentReply.prototype.clearRisksList = function() {
  return this.setRisksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskGetLatestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskGetLatestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetLatestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfRisks: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetLatestRequest}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskGetLatestRequest;
  return proto.riskmanager.api.risks.v1.RiskGetLatestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetLatestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetLatestRequest}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfRisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskGetLatestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetLatestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetLatestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfRisks();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 number_of_risks = 1;
 * @return {number}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestRequest.prototype.getNumberOfRisks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.riskmanager.api.risks.v1.RiskGetLatestRequest} returns this
 */
proto.riskmanager.api.risks.v1.RiskGetLatestRequest.prototype.setNumberOfRisks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskGetLatestReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskGetLatestReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    risksList: jspb.Message.toObjectList(msg.getRisksList(),
    proto.riskmanager.api.risks.v1.Risk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetLatestReply}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskGetLatestReply;
  return proto.riskmanager.api.risks.v1.RiskGetLatestReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetLatestReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetLatestReply}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.risks.v1.Risk;
      reader.readMessage(value,proto.riskmanager.api.risks.v1.Risk.deserializeBinaryFromReader);
      msg.addRisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskGetLatestReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskGetLatestReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.riskmanager.api.risks.v1.Risk.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Risk risks = 1;
 * @return {!Array<!proto.riskmanager.api.risks.v1.Risk>}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.prototype.getRisksList = function() {
  return /** @type{!Array<!proto.riskmanager.api.risks.v1.Risk>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.riskmanager.api.risks.v1.Risk, 1));
};


/**
 * @param {!Array<!proto.riskmanager.api.risks.v1.Risk>} value
 * @return {!proto.riskmanager.api.risks.v1.RiskGetLatestReply} returns this
*/
proto.riskmanager.api.risks.v1.RiskGetLatestReply.prototype.setRisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.riskmanager.api.risks.v1.Risk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.riskmanager.api.risks.v1.Risk}
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.prototype.addRisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.riskmanager.api.risks.v1.Risk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.riskmanager.api.risks.v1.RiskGetLatestReply} returns this
 */
proto.riskmanager.api.risks.v1.RiskGetLatestReply.prototype.clearRisksList = function() {
  return this.setRisksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskDeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskDeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskDeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskDeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskDeleteRequest}
 */
proto.riskmanager.api.risks.v1.RiskDeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskDeleteRequest;
  return proto.riskmanager.api.risks.v1.RiskDeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskDeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskDeleteRequest}
 */
proto.riskmanager.api.risks.v1.RiskDeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskDeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskDeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskDeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskDeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string risk_id = 1;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.RiskDeleteRequest.prototype.getRiskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.RiskDeleteRequest} returns this
 */
proto.riskmanager.api.risks.v1.RiskDeleteRequest.prototype.setRiskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskDeleteReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskDeleteReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskDeleteReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskDeleteReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskDeleteReply}
 */
proto.riskmanager.api.risks.v1.RiskDeleteReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskDeleteReply;
  return proto.riskmanager.api.risks.v1.RiskDeleteReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskDeleteReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskDeleteReply}
 */
proto.riskmanager.api.risks.v1.RiskDeleteReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskDeleteReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskDeleteReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskDeleteReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskDeleteReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool deleted = 1;
 * @return {boolean}
 */
proto.riskmanager.api.risks.v1.RiskDeleteReply.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.riskmanager.api.risks.v1.RiskDeleteReply} returns this
 */
proto.riskmanager.api.risks.v1.RiskDeleteReply.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTerm: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchRequest}
 */
proto.riskmanager.api.risks.v1.RiskSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskSearchRequest;
  return proto.riskmanager.api.risks.v1.RiskSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchRequest}
 */
proto.riskmanager.api.risks.v1.RiskSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_term = 1;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.RiskSearchRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchRequest} returns this
 */
proto.riskmanager.api.risks.v1.RiskSearchRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskSearchReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchHitsList: jspb.Message.toObjectList(msg.getSearchHitsList(),
    proto.riskmanager.api.risks.v1.RiskSearchHit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchReply}
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskSearchReply;
  return proto.riskmanager.api.risks.v1.RiskSearchReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchReply}
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.risks.v1.RiskSearchHit;
      reader.readMessage(value,proto.riskmanager.api.risks.v1.RiskSearchHit.deserializeBinaryFromReader);
      msg.addSearchHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskSearchReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchHitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.riskmanager.api.risks.v1.RiskSearchHit.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RiskSearchHit search_hits = 1;
 * @return {!Array<!proto.riskmanager.api.risks.v1.RiskSearchHit>}
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.prototype.getSearchHitsList = function() {
  return /** @type{!Array<!proto.riskmanager.api.risks.v1.RiskSearchHit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.riskmanager.api.risks.v1.RiskSearchHit, 1));
};


/**
 * @param {!Array<!proto.riskmanager.api.risks.v1.RiskSearchHit>} value
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchReply} returns this
*/
proto.riskmanager.api.risks.v1.RiskSearchReply.prototype.setSearchHitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchHit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchHit}
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.prototype.addSearchHits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.riskmanager.api.risks.v1.RiskSearchHit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchReply} returns this
 */
proto.riskmanager.api.risks.v1.RiskSearchReply.prototype.clearSearchHitsList = function() {
  return this.setSearchHitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.risks.v1.RiskSearchHit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchHit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.toObject = function(includeInstance, msg) {
  var f, obj = {
    match: jspb.Message.getFieldWithDefault(msg, 1, ""),
    risk: (f = msg.getRisk()) && proto.riskmanager.api.risks.v1.Risk.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchHit}
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.risks.v1.RiskSearchHit;
  return proto.riskmanager.api.risks.v1.RiskSearchHit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchHit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchHit}
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatch(value);
      break;
    case 2:
      var value = new proto.riskmanager.api.risks.v1.Risk;
      reader.readMessage(value,proto.riskmanager.api.risks.v1.Risk.deserializeBinaryFromReader);
      msg.setRisk(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.risks.v1.RiskSearchHit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.risks.v1.RiskSearchHit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRisk();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.riskmanager.api.risks.v1.Risk.serializeBinaryToWriter
    );
  }
};


/**
 * optional string match = 1;
 * @return {string}
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.prototype.getMatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchHit} returns this
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.prototype.setMatch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Risk risk = 2;
 * @return {?proto.riskmanager.api.risks.v1.Risk}
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.prototype.getRisk = function() {
  return /** @type{?proto.riskmanager.api.risks.v1.Risk} */ (
    jspb.Message.getWrapperField(this, proto.riskmanager.api.risks.v1.Risk, 2));
};


/**
 * @param {?proto.riskmanager.api.risks.v1.Risk|undefined} value
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchHit} returns this
*/
proto.riskmanager.api.risks.v1.RiskSearchHit.prototype.setRisk = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.riskmanager.api.risks.v1.RiskSearchHit} returns this
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.prototype.clearRisk = function() {
  return this.setRisk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.riskmanager.api.risks.v1.RiskSearchHit.prototype.hasRisk = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.riskmanager.api.risks.v1);
