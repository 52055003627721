import { AppBar, Toolbar } from '@material-ui/core';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Link, Router } from 'react-router-dom';
import App from './App';
import history from './history';
import CreateStore from './store';
import logo from './assets/risk.png';

const store = CreateStore();

const rootElement = document.getElementById('root');
ReactDOM.render(
  <StrictMode>
    <Router basename="/" history={history}>
      <AppBar color="inherit">
        <Toolbar>
          <Link to="/">
            <img src={logo} style={{ height: '45px' }} alt="logo" />
          </Link>
        </Toolbar>
      </AppBar>
      <App store={store} />
    </Router>
  </StrictMode>,
  rootElement,
);
