import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from '@material-ui/core';

const AddDialog = ({ show, onAdd, onClose }) => {
  const [addDialog, setAddDialog] = useState({
    name: '',
    probability: '',
    impact: '',
    details: '',
  });
  const close = () => {
    setAddDialog({
      name: '',
      probability: '',
      impact: '',
      details: '',
    });
    onClose();
  };

  const isMobile = useMediaQuery('(max-width:850px)');

  return (
    <Dialog
      open={show}
      onClose={close}
      fullWidth
      maxWidth={!isMobile && 'sm'}
      fullScreen={isMobile}
    >
      {show && (
      <>
        <DialogTitle>Add Item</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <FormControl fullWidth>
                <TextField
                  autoFocus
                  label="Name"
                  value={addDialog.name}
                  onChange={(event) => {
                    setAddDialog({ ...addDialog, name: event.target.value });
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <InputLabel id="add-dialog-probability">Probability</InputLabel>
                <Select
                  labelId="add-dialog-probability"
                  value={addDialog.probability}
                  onChange={(event) => {
                    setAddDialog({
                      ...addDialog,
                      probability: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <InputLabel id="add-dialog-impact">Impact</InputLabel>
                <Select
                  labelId="add-dialog-impact"
                  value={addDialog.impact}
                  onChange={(event) => {
                    setAddDialog({
                      ...addDialog,
                      impact: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Score"
                  value={addDialog.probability * addDialog.impact || ''}
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <TextField
                  label="Details"
                  value={addDialog.details}
                  multiline
                  minRows={3}
                  onChange={(event) => {
                    setAddDialog({ ...addDialog, details: event.target.value });
                  }}
                />
              </FormControl>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={close}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={
                            addDialog.name === ''
                            || addDialog.probability === ''
                            || addDialog.impact === ''
                        }
            onClick={() => {
              onAdd(
                addDialog.name,
                addDialog.probability,
                addDialog.impact,
                addDialog.details,
              );
              close();
            }}
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </>
      )}
    </Dialog>
  );
};

export default AddDialog;
