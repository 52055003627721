import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import short from 'short-uuid';
import {
  Assessment,
  AssessmentSaveRequest,
  AssessmentGetRequest,
  AssessmentGetLatestRequest,
  AssessmentUpdateStateRequest,
} from './grpc/assessments/assessments_pb';
import {
  Risk,
  RiskDeleteRequest,
  RiskGetAllAssessmentRequest,
  RiskSaveRequest,
} from './grpc/risks/risks_pb';
import { AssessmentServiceClient } from './grpc/assessments/assessments_grpc_web_pb';
import { RiskServiceClient } from './grpc/risks/risks_grpc_web_pb';

const { NODE_ENV } = process.env;

const storeClientHost = NODE_ENV === 'development'
  ? 'http://localhost:8001/rpc'
  : `${window.location.origin}/rpc`;

dayjs.extend(LocalizedFormat);

const riskDataObject = (risk) => ({
  id: risk[0],
  name: risk[1],
  details: risk[2],
  probability: risk[3],
  impact: risk[4],
  createdOn: dayjs(risk[5]),
  updatedOn: dayjs(risk[6]),
});

export const assessmentStates = ['Create', 'Update', 'Plan', 'Do', 'Review'];

const assessmentDataObject = (assessment) => ({
  name: assessment[0],
  slug: assessment[1],
  description: assessment[2],
  createdOn: dayjs(assessment[3]),
  updatedOn: dayjs(assessment[4]),
  state: assessment[7] || 0,
});

const CreateStore = () => {
  const assessmentService = new AssessmentServiceClient(storeClientHost);
  const riskService = new RiskServiceClient(storeClientHost);
  const store = {
    assessmentService,
    riskService,
    getAssessment: (slug) => new Promise((resolve, reject) => {
      const req = new AssessmentGetRequest();
      req.setSlug(slug);
      store.assessmentService.get(req, {}, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve(assessmentDataObject(resp.array[0]));
        }
      });
    }),
    createAssessment: (name, description) => new Promise((resolve, reject) => {
      const req = new AssessmentSaveRequest();
      const assessment = new Assessment();
      assessment.setSlug(short.generate());
      assessment.setName(name);
      assessment.setDescription(description);
      req.setAssessment(assessment);
      store.assessmentService.save(req, {}, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve(assessmentDataObject(resp.array[0]));
        }
      });
    }),
    editAssessment: (slug, name, description) => new Promise((resolve, reject) => {
      const req = new AssessmentSaveRequest();
      const assessment = new Assessment();
      assessment.setSlug(slug);
      assessment.setName(name);
      assessment.setDescription(description);
      req.setAssessment(assessment);
      store.assessmentService.save(req, {}, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve(assessmentDataObject(resp.array[0]));
        }
      });
    }),
    updateState: (slug, state) => new Promise((resolve, reject) => {
      const req = new AssessmentUpdateStateRequest();
      req.setSlug(slug);
      req.setState(state);
      store.assessmentService.updateState(req, {}, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve(resp.array[0]);
        }
      });
    }),
    getLatestAssessments: (amount = 5) => new Promise((resolve, reject) => {
      const req = new AssessmentGetLatestRequest();
      req.setNumberOfAssessments(amount);
      store.assessmentService.getLatest(req, {}, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve(resp.array[0].map((a) => assessmentDataObject(a)));
        }
      });
    }),
    getRisks: (slug) => new Promise((resolve, reject) => {
      const req = new RiskGetAllAssessmentRequest();
      req.setAssessmentSlug(slug);
      store.riskService.getFromAssessment(req, {}, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve({
            riskData: resp.array[1].map((r) => riskDataObject(r)),
            assessmentData: assessmentDataObject(resp.array[0]),
          });
        }
      });
    }),
    addRisk: (slug, riskData) => new Promise((resolve, reject) => {
      const req = new RiskSaveRequest();
      const risk = new Risk();

      risk.setName(riskData.name);
      risk.setProbability(riskData.probability);
      risk.setImpact(riskData.impact);
      risk.setDescription(riskData.details);
      req.setAssessmentSlug(slug);
      req.setRisk(risk);

      store.riskService.save(req, {}, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve(riskDataObject(resp.array[0]));
        }
      });
    }),
    editRisk: (slug, riskData) => new Promise((resolve, reject) => {
      const req = new RiskSaveRequest();
      const risk = new Risk();

      risk.setId(riskData.id);
      risk.setName(riskData.name);
      risk.setProbability(riskData.probability);
      risk.setImpact(riskData.impact);
      risk.setDescription(riskData.details);
      req.setAssessmentSlug(slug);
      req.setRisk(risk);

      store.riskService.save(req, {}, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve(riskDataObject(resp.array[0]));
        }
      });
    }),
    deleteRisk: (riskData) => new Promise((resolve, reject) => {
      const req = new RiskDeleteRequest();
      req.setRiskId(riskData.id);
      store.riskService.delete(req, {}, (err, resp) => {
        if (err) {
          reject(err);
        } else {
          resolve(riskDataObject(resp.array[0]));
        }
      });
    }),
  };
  return store;
};

export default CreateStore;
