import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from '@material-ui/core';

const EditDialog = ({
  visible, risk, onEdit, onClose,
}) => {
  const [state, setState] = React.useState({});

  useEffect(() => {
    if (visible) {
      setState(risk);
    }
  }, [visible, risk]);

  const handleEdit = () => {
    onEdit(
      state.id,
      state.name,
      state.probability,
      state.impact,
      state.details,
    );
    onClose();
  };

  const isMobile = useMediaQuery('(max-width:850px)');

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      fullWidth
      maxWidth={!isMobile && 'sm'}
      fullScreen={isMobile}
    >
      {visible && (
      <>
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <FormControl fullWidth>
                <TextField
                  autoFocus
                  label="Name"
                  value={state.name}
                  onChange={(event) => {
                    setState({ ...state, name: event.target.value });
                  }}
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <InputLabel id="edit-dialog-probability">
                  Probability
                </InputLabel>
                <Select
                  labelId="edit-dialog-probability"
                  value={state.probability}
                  onChange={(event) => {
                    setState({
                      ...state,
                      probability: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <InputLabel id="edit-dialog-impact">Impact</InputLabel>
                <Select
                  labelId="edit-dialog-impact"
                  value={state.impact}
                  onChange={(event) => {
                    setState({
                      ...state,
                      impact: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Score"
                  value={state.probability * state.impact || ''}
                />
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                <TextField
                  label="Details"
                  value={state.details}
                  multiline
                  minRows={3}
                  onChange={(event) => {
                    setState({
                      ...state,
                      details: event.target.value,
                    });
                  }}
                />
              </FormControl>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleEdit}
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </>
      )}
    </Dialog>
  );
};

export default EditDialog;
