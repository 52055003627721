/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import Loading from './Loading';
import Matrix from './Matrix';
import CreateStore from '../../store';

const Assessment = ({ id }) => {
  const [state, setState] = useState({
    riskData: [],
    assessmentData: {},
    component: 'loading',
  });
  const store = CreateStore();

  const handleAdd = async (name, probability, impact, details) => {
    try {
      await store.addRisk(id, {
        name,
        probability,
        impact,
        details,
      });
      const { riskData } = await store.getRisks(id);
      setState({ ...state, riskData });
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleEdit = async (riskId, name, probability, impact, details) => {
    try {
      await store.editRisk(id, {
        id: riskId,
        name,
        probability,
        impact,
        details,
      });
      const { riskData } = await store.getRisks(id);
      setState({ ...state, riskData });
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDelete = async (riskId) => {
    try {
      const risk = state.riskData.filter((r) => r.id === riskId)[0];
      await store.deleteRisk(risk);
      const { riskData } = await store.getRisks(id);
      setState({ ...state, riskData });
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleEditAssessment = async (title, description, assessmentState) => {
    try {
      let shouldUpdateAssessmentState;
      const assessment = await store.editAssessment(state.assessmentData.slug, title, description);
      if (assessmentState !== state.assessmentData?.state) {
        shouldUpdateAssessmentState = await store.updateState(
          state.assessmentData.slug,
          assessmentState,
        );
        if (shouldUpdateAssessmentState) {
          assessment.state = assessmentState;
        }
      }
      setState({ ...state, assessmentData: assessment });
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const assessmentData = await store.getAssessment(id);
        const { riskData } = await store.getRisks(id);
        setState({
          riskData,
          assessmentData,
          component: 'matrix',
        });
      } catch (err) {
        console.log('err', err);
        setState({ riskData: [], component: 'redirect' });
      }
    };
    init();
  }, []);

  return state.component === 'loading' ? <Loading /> : (state.component === 'matrix' ? (
    <Matrix
      riskData={state.riskData}
      assessmentData={state.assessmentData}
      onEditAssessment={handleEditAssessment}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  ) : <Redirect exact to="/" />);
};

export default Assessment;
