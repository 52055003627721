import React, { useRef, useState } from 'react';
import {
  Box,
  ButtonBase,
  Fab,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import {
  Add,
  Edit,
  Delete,
  Info,
} from '@material-ui/icons';

const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return `${str.slice(0, num)}...`;
};

const DesktopTable = ({
  data,
  setShowAddDialog,
  setEditDialog,
  onDelete,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverDetails, setPopoverDetails] = useState(0);
  const anchorEl = useRef(null);

  const renderPopover = () => (
    <Popover
      open={showPopover}
      anchorEl={anchorEl.current}
      onClose={() => {
        setShowPopover(false);
      }}
    >
      <Typography style={{ padding: '16px' }}>{popoverDetails}</Typography>
    </Popover>
  );
  return (
    <>
      { renderPopover() }
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Probability</TableCell>
            <TableCell>Impact</TableCell>
            <TableCell>Score</TableCell>
            <TableCell>Details</TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <Fab
                size="small"
                color="primary"
                onClick={() => {
                  setShowAddDialog(true);
                }}
              >
                <Add />
              </Fab>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((risk) => (
            <TableRow key={risk.id}>
              <TableCell>{risk.name}</TableCell>
              <TableCell>{risk.probability}</TableCell>
              <TableCell>{risk.impact}</TableCell>
              <TableCell>{risk.probability * risk.impact}</TableCell>
              <TableCell>
                <ButtonBase
                  onClick={(event) => {
                    anchorEl.current = event.target;
                    setShowPopover(true);
                    setPopoverDetails(risk.details);
                  }}
                >
                  <Typography variant="body2">
                    {risk.details && truncateString(risk.details, 20)}
                  </Typography>
                </ButtonBase>
                <ButtonBase
                  onClick={(event) => {
                    anchorEl.current = event.target;
                    setShowPopover(true);
                    setPopoverDetails(
                      <>
                        Created on:
                        {' '}
                        {risk.createdOn.format('L')}
                        {!!(risk.createdOn - risk.updatedOn)
                                                    && (
                                                    <>
                                                      <br />
                                                      Updated on:
                                                      {' '}
                                                      {risk.updatedOn.format('L')}
                                                    </>
                                                    )}
                      </>,
                    );
                  }}
                >
                  <Box sx={{ ml: 1, mt: 0.2 }}>
                    <Info fontSize="small" color="disabled" />
                  </Box>
                </ButtonBase>
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                <IconButton
                  onClick={() => {
                    setEditDialog({
                      visible: true,
                      risk: {
                        id: risk.id,
                        name: risk.name,
                        probability: risk.probability,
                        impact: risk.impact,
                        details: risk.details,
                      },
                    });
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    onDelete(risk.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default DesktopTable;
