import React, { useRef, useState } from 'react';
import {
  Box,
  ButtonBase,
  Collapse,
  Fab,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import {
  Add,
  Edit,
  ExpandMore,
  ExpandLess,
  Delete,
  Info,
} from '@material-ui/icons';

const MobileTable = ({
  data,
  setShowAddDialog,
  setEditDialog,
  onDelete,
}) => {
  const [collapse, setCollapse] = useState({ id: 0, show: false });
  const [showPopover, setShowPopover] = useState(false);
  const [popoverDetails, setPopoverDetails] = useState(0);
  const anchorEl = useRef(null);

  const renderPopover = () => (
    <Popover
      open={showPopover}
      anchorEl={anchorEl.current}
      onClose={() => {
        setShowPopover(false);
      }}
    >
      <Typography style={{ padding: '16px' }}>{popoverDetails}</Typography>
    </Popover>
  );

  const handleEdit = (risk) => {
    setEditDialog({
      visible: true,
      risk: {
        id: risk.id,
        name: risk.name,
        probability: risk.probability,
        impact: risk.impact,
        details: risk.details,
      },
    });
  };

  return (
    <>
      {renderPopover()}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell style={{ padding: '6px' }}>Probability</TableCell>
            <TableCell style={{ padding: '6px' }}>Impact</TableCell>
            <TableCell style={{ textAlign: 'center', padding: '6px' }}>
              <Fab
                color="primary"
                size="small"
                onClick={() => {
                  setShowAddDialog(true);
                }}
              >
                <Add />
              </Fab>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((risk) => (
            <React.Fragment key={risk.id}>
              <TableRow>
                <TableCell>{risk.name}</TableCell>
                <TableCell>{risk.probability}</TableCell>
                <TableCell>{risk.impact}</TableCell>
                <TableCell style={{ textAlign: 'center', padding: '6px' }}>
                  {collapse.id === risk.id && collapse.show ? (
                    <IconButton
                      onClick={() => {
                        setCollapse({ id: 0, show: false });
                      }}
                    >
                      <ExpandLess />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setCollapse({ id: risk.id, show: true });
                      }}
                    >
                      <ExpandMore />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    borderBottom: 'none',
                  }}
                  colSpan={4}
                >
                  <Collapse
                    in={collapse.id === risk.id && collapse.show}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box margin={1}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">{risk.id}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Score</TableCell>
                            <TableCell align="right">
                              {risk.probability * risk.impact}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Details</TableCell>
                            <TableCell align="right">
                              {risk.details}
                              <ButtonBase
                                onClick={(event) => {
                                  anchorEl.current = event.target;
                                  setShowPopover(true);
                                  setPopoverDetails(
                                    <>
                                      Created on:
                                      {' '}
                                      {risk.createdOn.format('L')}
                                      {!!(risk.createdOn - risk.updatedOn) && (
                                      <>
                                        <br />
                                        Updated on:
                                        {' '}
                                        {risk.updatedOn.format('L')}
                                      </>
                                      )}
                                    </>,
                                  );
                                }}
                              >
                                <Box sx={{ ml: 1, mt: 0.2 }}>
                                  <Info fontSize="small" color="disabled" />
                                </Box>
                              </ButtonBase>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: 0 }}>
                              <IconButton
                                onClick={() => {
                                  onDelete(risk.id);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                            <TableCell align="right" style={{ padding: 0 }}>
                              <IconButton
                                onClick={() => { handleEdit(risk); }}
                              >
                                <Edit />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default MobileTable;
