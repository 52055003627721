// source: assessments/assessments.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.riskmanager.api.assessments.v1.Assessment', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentDeleteReply', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentGetReply', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentGetRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentSaveReply', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentSaveRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentSearchHit', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentSearchReply', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentSearchRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest', null, global);
goog.exportSymbol('proto.riskmanager.api.assessments.v1.State', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.Assessment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.Assessment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.Assessment.displayName = 'proto.riskmanager.api.assessments.v1.Assessment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentSaveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentSaveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentSaveReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentSaveReply.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentSaveReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentGetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentGetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentGetRequest.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentGetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentGetReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentGetReply.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentGetReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentDeleteReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentDeleteReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.riskmanager.api.assessments.v1.AssessmentSearchReply.repeatedFields_, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentSearchReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentSearchReply.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentSearchReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentSearchHit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentSearchHit.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentSearchHit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.repeatedFields_, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.displayName = 'proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.Assessment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.Assessment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.Assessment.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    created: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updated: jspb.Message.getFieldWithDefault(msg, 5, ""),
    owner: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pb_class: jspb.Message.getFieldWithDefault(msg, 7, ""),
    state: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.Assessment}
 */
proto.riskmanager.api.assessments.v1.Assessment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.Assessment;
  return proto.riskmanager.api.assessments.v1.Assessment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.Assessment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.Assessment}
 */
proto.riskmanager.api.assessments.v1.Assessment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdated(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClass(value);
      break;
    case 8:
      var value = /** @type {!proto.riskmanager.api.assessments.v1.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.Assessment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.Assessment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.Assessment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdated();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getClass();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.Assessment} returns this
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.Assessment} returns this
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.Assessment} returns this
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string created = 4;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.getCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.Assessment} returns this
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.setCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string updated = 5;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.getUpdated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.Assessment} returns this
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.setUpdated = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string owner = 6;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.Assessment} returns this
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string class = 7;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.getClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.Assessment} returns this
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.setClass = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional State state = 8;
 * @return {!proto.riskmanager.api.assessments.v1.State}
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.getState = function() {
  return /** @type {!proto.riskmanager.api.assessments.v1.State} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.State} value
 * @return {!proto.riskmanager.api.assessments.v1.Assessment} returns this
 */
proto.riskmanager.api.assessments.v1.Assessment.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessment: (f = msg.getAssessment()) && proto.riskmanager.api.assessments.v1.Assessment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentSaveRequest;
  return proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.assessments.v1.Assessment;
      reader.readMessage(value,proto.riskmanager.api.assessments.v1.Assessment.deserializeBinaryFromReader);
      msg.setAssessment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.riskmanager.api.assessments.v1.Assessment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Assessment assessment = 1;
 * @return {?proto.riskmanager.api.assessments.v1.Assessment}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.prototype.getAssessment = function() {
  return /** @type{?proto.riskmanager.api.assessments.v1.Assessment} */ (
    jspb.Message.getWrapperField(this, proto.riskmanager.api.assessments.v1.Assessment, 1));
};


/**
 * @param {?proto.riskmanager.api.assessments.v1.Assessment|undefined} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest} returns this
*/
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.prototype.setAssessment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.prototype.clearAssessment = function() {
  return this.setAssessment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveRequest.prototype.hasAssessment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentSaveReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSaveReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessment: (f = msg.getAssessment()) && proto.riskmanager.api.assessments.v1.Assessment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSaveReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentSaveReply;
  return proto.riskmanager.api.assessments.v1.AssessmentSaveReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSaveReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSaveReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.assessments.v1.Assessment;
      reader.readMessage(value,proto.riskmanager.api.assessments.v1.Assessment.deserializeBinaryFromReader);
      msg.setAssessment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentSaveReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSaveReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.riskmanager.api.assessments.v1.Assessment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Assessment assessment = 1;
 * @return {?proto.riskmanager.api.assessments.v1.Assessment}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.prototype.getAssessment = function() {
  return /** @type{?proto.riskmanager.api.assessments.v1.Assessment} */ (
    jspb.Message.getWrapperField(this, proto.riskmanager.api.assessments.v1.Assessment, 1));
};


/**
 * @param {?proto.riskmanager.api.assessments.v1.Assessment|undefined} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSaveReply} returns this
*/
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.prototype.setAssessment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSaveReply} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.prototype.clearAssessment = function() {
  return this.setAssessment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.riskmanager.api.assessments.v1.AssessmentSaveReply.prototype.hasAssessment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentGetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentGetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentGetRequest;
  return proto.riskmanager.api.assessments.v1.AssessmentGetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentGetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentGetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetRequest} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentGetRequest.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentGetReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessment: (f = msg.getAssessment()) && proto.riskmanager.api.assessments.v1.Assessment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentGetReply;
  return proto.riskmanager.api.assessments.v1.AssessmentGetReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.assessments.v1.Assessment;
      reader.readMessage(value,proto.riskmanager.api.assessments.v1.Assessment.deserializeBinaryFromReader);
      msg.setAssessment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentGetReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.riskmanager.api.assessments.v1.Assessment.serializeBinaryToWriter
    );
  }
};


/**
 * optional Assessment assessment = 1;
 * @return {?proto.riskmanager.api.assessments.v1.Assessment}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply.prototype.getAssessment = function() {
  return /** @type{?proto.riskmanager.api.assessments.v1.Assessment} */ (
    jspb.Message.getWrapperField(this, proto.riskmanager.api.assessments.v1.Assessment, 1));
};


/**
 * @param {?proto.riskmanager.api.assessments.v1.Assessment|undefined} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetReply} returns this
*/
proto.riskmanager.api.assessments.v1.AssessmentGetReply.prototype.setAssessment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetReply} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply.prototype.clearAssessment = function() {
  return this.setAssessment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetReply.prototype.hasAssessment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest;
  return proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentDeleteReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentDeleteReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentDeleteReply;
  return proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentDeleteReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentDeleteReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentDeleteReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool deleted = 1;
 * @return {boolean}
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentDeleteReply} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchTerm: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentSearchRequest;
  return proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchTerm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string search_term = 1;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.prototype.getSearchTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchRequest} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchRequest.prototype.setSearchTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentSearchReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchHitsList: jspb.Message.toObjectList(msg.getSearchHitsList(),
    proto.riskmanager.api.assessments.v1.AssessmentSearchHit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentSearchReply;
  return proto.riskmanager.api.assessments.v1.AssessmentSearchReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.assessments.v1.AssessmentSearchHit;
      reader.readMessage(value,proto.riskmanager.api.assessments.v1.AssessmentSearchHit.deserializeBinaryFromReader);
      msg.addSearchHits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentSearchReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchHitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.riskmanager.api.assessments.v1.AssessmentSearchHit.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AssessmentSearchHit search_hits = 1;
 * @return {!Array<!proto.riskmanager.api.assessments.v1.AssessmentSearchHit>}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.prototype.getSearchHitsList = function() {
  return /** @type{!Array<!proto.riskmanager.api.assessments.v1.AssessmentSearchHit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.riskmanager.api.assessments.v1.AssessmentSearchHit, 1));
};


/**
 * @param {!Array<!proto.riskmanager.api.assessments.v1.AssessmentSearchHit>} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchReply} returns this
*/
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.prototype.setSearchHitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.prototype.addSearchHits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.riskmanager.api.assessments.v1.AssessmentSearchHit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchReply} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchReply.prototype.clearSearchHitsList = function() {
  return this.setSearchHitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentSearchHit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.toObject = function(includeInstance, msg) {
  var f, obj = {
    match: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assessment: (f = msg.getAssessment()) && proto.riskmanager.api.assessments.v1.Assessment.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentSearchHit;
  return proto.riskmanager.api.assessments.v1.AssessmentSearchHit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatch(value);
      break;
    case 2:
      var value = new proto.riskmanager.api.assessments.v1.Assessment;
      reader.readMessage(value,proto.riskmanager.api.assessments.v1.Assessment.deserializeBinaryFromReader);
      msg.setAssessment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentSearchHit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMatch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssessment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.riskmanager.api.assessments.v1.Assessment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string match = 1;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.prototype.getMatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.prototype.setMatch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Assessment assessment = 2;
 * @return {?proto.riskmanager.api.assessments.v1.Assessment}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.prototype.getAssessment = function() {
  return /** @type{?proto.riskmanager.api.assessments.v1.Assessment} */ (
    jspb.Message.getWrapperField(this, proto.riskmanager.api.assessments.v1.Assessment, 2));
};


/**
 * @param {?proto.riskmanager.api.assessments.v1.Assessment|undefined} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit} returns this
*/
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.prototype.setAssessment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentSearchHit} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.prototype.clearAssessment = function() {
  return this.setAssessment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.riskmanager.api.assessments.v1.AssessmentSearchHit.prototype.hasAssessment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfAssessments: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest;
  return proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfAssessments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfAssessments();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 number_of_assessments = 1;
 * @return {number}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.prototype.getNumberOfAssessments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest.prototype.setNumberOfAssessments = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    assessmentsList: jspb.Message.toObjectList(msg.getAssessmentsList(),
    proto.riskmanager.api.assessments.v1.Assessment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply;
  return proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.riskmanager.api.assessments.v1.Assessment;
      reader.readMessage(value,proto.riskmanager.api.assessments.v1.Assessment.deserializeBinaryFromReader);
      msg.addAssessments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssessmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.riskmanager.api.assessments.v1.Assessment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Assessment assessments = 1;
 * @return {!Array<!proto.riskmanager.api.assessments.v1.Assessment>}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.prototype.getAssessmentsList = function() {
  return /** @type{!Array<!proto.riskmanager.api.assessments.v1.Assessment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.riskmanager.api.assessments.v1.Assessment, 1));
};


/**
 * @param {!Array<!proto.riskmanager.api.assessments.v1.Assessment>} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply} returns this
*/
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.prototype.setAssessmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.Assessment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.riskmanager.api.assessments.v1.Assessment}
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.prototype.addAssessments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.riskmanager.api.assessments.v1.Assessment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.prototype.clearAssessmentsList = function() {
  return this.setAssessmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest;
  return proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 2:
      var value = /** @type {!proto.riskmanager.api.assessments.v1.State} */ (reader.readEnum());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional State state = 2;
 * @return {!proto.riskmanager.api.assessments.v1.State}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.prototype.getState = function() {
  return /** @type {!proto.riskmanager.api.assessments.v1.State} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.State} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.prototype.toObject = function(opt_includeInstance) {
  return proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply;
  return proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply} returns this
 */
proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.riskmanager.api.assessments.v1.State = {
  CREATE: 0,
  UPDATE: 1,
  PLAN: 2,
  DO: 3,
  REVIEW: 4
};

goog.object.extend(exports, proto.riskmanager.api.assessments.v1);
