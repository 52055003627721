import React from 'react';
import {
  IconButton,
  Link,
  useMediaQuery,
} from '@material-ui/core';

import {
  GetApp,
} from '@material-ui/icons';

import AddDialog from './AddDialog';
import EditDialog from './EditDialog';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';

const RiskTable = ({
  data,
  onAdd,
  onEdit,
  onDelete,
}) => {
  const [showAddDialog, setShowAddDialog] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState({
    visible: false,
    risk: {
      id: 0,
      name: '',
      probability: '',
      impact: '',
      details: '',
    },
  });

  const isMobile = useMediaQuery('(max-width:850px)');
  const toCSV = (d) => {
    const fields = Object.keys(d[0]);
    const replacer = (key, value) => (value === null ? '' : value);
    let csv = d.map((row) => fields
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(','));

    csv.unshift(fields.join(',')); // add header column
    csv = csv.join('\r\n');
    return csv;
  };

  const handleEditClose = () => {
    setEditDialog((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };
  const handleAddClose = () => {
    setShowAddDialog(false);
  };

  return (
    <>
      <AddDialog
        show={showAddDialog}
        onAdd={onAdd}
        onClose={handleAddClose}
      />
      <EditDialog
        visible={editDialog.visible}
        risk={editDialog.risk}
        onEdit={onEdit}
        onClose={handleEditClose}
      />
      {isMobile ? (
        <MobileTable
          data={data}
          setShowAddDialog={setShowAddDialog}
          setEditDialog={setEditDialog}
          onDelete={onDelete}
        />
      ) : (
        <DesktopTable
          data={data}
          setShowAddDialog={setShowAddDialog}
          setEditDialog={setEditDialog}
          onDelete={onDelete}
        />
      )}
      {data && data.length > 0 ? (
        <Link
          component={IconButton}
          download="data.csv"
          href={`data:application/octet-stream,${encodeURI(toCSV(data))}`}
          color="primary"
          style={{ float: 'right' }}
        >
          <GetApp />
        </Link>
      ) : (
        ''
      )}
    </>
  );
};

export default RiskTable;
