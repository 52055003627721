/**
 * @fileoverview gRPC-Web generated client stub for riskmanager.api.assessments.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.riskmanager = {};
proto.riskmanager.api = {};
proto.riskmanager.api.assessments = {};
proto.riskmanager.api.assessments.v1 = require('./assessments_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.riskmanager.api.assessments.v1.AssessmentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.riskmanager.api.assessments.v1.AssessmentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.assessments.v1.AssessmentSaveRequest,
 *   !proto.riskmanager.api.assessments.v1.AssessmentSaveReply>}
 */
const methodDescriptor_AssessmentService_Save = new grpc.web.MethodDescriptor(
  '/riskmanager.api.assessments.v1.AssessmentService/Save',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.assessments.v1.AssessmentSaveRequest,
  proto.riskmanager.api.assessments.v1.AssessmentSaveReply,
  /**
   * @param {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.assessments.v1.AssessmentSaveReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.assessments.v1.AssessmentSaveReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.assessments.v1.AssessmentSaveReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.assessments.v1.AssessmentServiceClient.prototype.save =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/Save',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_Save,
      callback);
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSaveRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.assessments.v1.AssessmentSaveReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.assessments.v1.AssessmentServicePromiseClient.prototype.save =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/Save',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_Save);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.assessments.v1.AssessmentGetRequest,
 *   !proto.riskmanager.api.assessments.v1.AssessmentGetReply>}
 */
const methodDescriptor_AssessmentService_Get = new grpc.web.MethodDescriptor(
  '/riskmanager.api.assessments.v1.AssessmentService/Get',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.assessments.v1.AssessmentGetRequest,
  proto.riskmanager.api.assessments.v1.AssessmentGetReply,
  /**
   * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.assessments.v1.AssessmentGetReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.assessments.v1.AssessmentGetReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.assessments.v1.AssessmentGetReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.assessments.v1.AssessmentServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/Get',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_Get,
      callback);
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.assessments.v1.AssessmentGetReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.assessments.v1.AssessmentServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/Get',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest,
 *   !proto.riskmanager.api.assessments.v1.AssessmentDeleteReply>}
 */
const methodDescriptor_AssessmentService_Delete = new grpc.web.MethodDescriptor(
  '/riskmanager.api.assessments.v1.AssessmentService/Delete',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest,
  proto.riskmanager.api.assessments.v1.AssessmentDeleteReply,
  /**
   * @param {!proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.assessments.v1.AssessmentDeleteReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.assessments.v1.AssessmentDeleteReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.assessments.v1.AssessmentDeleteReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.assessments.v1.AssessmentServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/Delete',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_Delete,
      callback);
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.assessments.v1.AssessmentDeleteReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.assessments.v1.AssessmentServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/Delete',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.assessments.v1.AssessmentSearchRequest,
 *   !proto.riskmanager.api.assessments.v1.AssessmentSearchReply>}
 */
const methodDescriptor_AssessmentService_Search = new grpc.web.MethodDescriptor(
  '/riskmanager.api.assessments.v1.AssessmentService/Search',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.assessments.v1.AssessmentSearchRequest,
  proto.riskmanager.api.assessments.v1.AssessmentSearchReply,
  /**
   * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.assessments.v1.AssessmentSearchReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.assessments.v1.AssessmentSearchReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.assessments.v1.AssessmentSearchReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.assessments.v1.AssessmentServiceClient.prototype.search =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/Search',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_Search,
      callback);
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentSearchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.assessments.v1.AssessmentSearchReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.assessments.v1.AssessmentServicePromiseClient.prototype.search =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/Search',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_Search);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest,
 *   !proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply>}
 */
const methodDescriptor_AssessmentService_GetLatest = new grpc.web.MethodDescriptor(
  '/riskmanager.api.assessments.v1.AssessmentService/GetLatest',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest,
  proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply,
  /**
   * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.assessments.v1.AssessmentServiceClient.prototype.getLatest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/GetLatest',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_GetLatest,
      callback);
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentGetLatestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.assessments.v1.AssessmentGetLatestReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.assessments.v1.AssessmentServicePromiseClient.prototype.getLatest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/GetLatest',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_GetLatest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest,
 *   !proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply>}
 */
const methodDescriptor_AssessmentService_UpdateState = new grpc.web.MethodDescriptor(
  '/riskmanager.api.assessments.v1.AssessmentService/UpdateState',
  grpc.web.MethodType.UNARY,
  proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest,
  proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply,
  /**
   * @param {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply.deserializeBinary
);


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.riskmanager.api.assessments.v1.AssessmentServiceClient.prototype.updateState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/UpdateState',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_UpdateState,
      callback);
};


/**
 * @param {!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.riskmanager.api.assessments.v1.AssessmentUpdateStateReply>}
 *     Promise that resolves to the response
 */
proto.riskmanager.api.assessments.v1.AssessmentServicePromiseClient.prototype.updateState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/riskmanager.api.assessments.v1.AssessmentService/UpdateState',
      request,
      metadata || {},
      methodDescriptor_AssessmentService_UpdateState);
};


module.exports = proto.riskmanager.api.assessments.v1;

