import React from 'react';
import {
  Chip,
  List,
  ListItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

const RiskMatrix = ({ data }) => {
  // \xa0 is non breaking space
  const impactLabel = [
    'Catastrophic\xa0(5)',
    'Critical\xa0(4)',
    'Moderate\xa0(3)',
    'Marginal\xa0(2)',
    'Negligible\xa0(1)',
  ];

  const probLabel = [
    'Frequent (5)',
    'Probable (4)',
    'Occasional (3)',
    'Remote (2)',
    'Improbable (1)',
  ];
  const scoreLabel = [
    [-25, -20, -15, -10, -8],
    [-20, -18, -12, -8, -4],
    [-15, -12, -9, -6, -3],
    [-10, -8, -6, -4, -2],
    [-5, -4, -3, -2, -1],
  ];

  const matrixCellStyle = (score) => {
    let backgroundColor;

    if (score <= -20) {
      backgroundColor = '#E13524';
    } else if (score <= -10) {
      backgroundColor = '#FEBC28';
    } else if (score <= -4) {
      backgroundColor = '#E7E849';
    } else {
      backgroundColor = '#78C043';
    }

    const style = {
      color: '#fff',
      textAlign: 'center',
      border: '1px #fff solid',
      backgroundColor,
    };
    return style;
  };

  const [showPopover, setShowPopover] = React.useState(false);

  // Array with [rowIndex, colIndex] to show which popover is active.
  const [activeCellIndex, setActiveCellIndex] = React.useState([0, 0]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMobile = useMediaQuery('(max-width:850px)');
  const handleClick = (rowIndex, colIndex, target) => {
    setAnchorEl(target);
    setActiveCellIndex([rowIndex, colIndex]);
    setShowPopover(true);
  };
  const getCountFromIndex = (rowIndex, colIndex) => {
    // Row Index and Column Indexes are ranging between 0 to 4, 0 being
    // highest probable and impactful, however data is ranging from
    // 1 to 5, 5 being highest probable and impactful
    // Subtracting 5 from index gives appropriate data value

    const probability = 5 - rowIndex;
    const impact = 5 - colIndex;
    let count = 0;
    data.forEach((risk) => {
      if (risk.probability === probability && risk.impact === impact) {
        count += 1;
      }
    });
    return count;
  };
  const renderCount = (rowIndex, colIndex) => {
    const count = getCountFromIndex(rowIndex, colIndex);
    return (count > 0) && (
    <Chip
      onClick={(event) => {
        handleClick(rowIndex, colIndex, event.currentTarget);
      }}
      label={count}
      style={{ backgroundColor: '#fff', color: '#000', fontWeight: 'bold' }}
      size="small"
    />
    );
  };

  const getDataFromIndex = (rowIndex, colIndex) => {
    // See comment at getCountFromIndex()
    const probability = 5 - rowIndex;
    const impact = 5 - colIndex;
    const d = [];
    d.forEach((risk) => {
      if (risk.probability === probability && risk.impact === impact) {
        d.push(risk);
      }
    });
    return d;
  };
  const renderPopovers = () => {
    const open = showPopover;
    const rowIndex = activeCellIndex[0];
    const colIndex = activeCellIndex[1];
    const d = getDataFromIndex(rowIndex, colIndex);

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setShowPopover(false);
        }}
      >
        <List style={{ padding: '8px' }}>
          {d.map((risk) => (
            <ListItem key={`popover-list-${risk.id}`}>
              <Typography>
                {risk.id}
                .
                {risk.name}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Popover>
    );
  };
  return (
    <>
      {renderPopovers()}
      <Table style={{ marginBottom: '50px', tableLayout: 'fixed' }}>
        {isMobile ? (
          ''
        ) : (
          <TableHead>
            <TableRow>
              <TableCell>Probability / Impact</TableCell>
              {impactLabel.map((el) => (
                <TableCell key={el}>{el}</TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {scoreLabel.map((row, rowIndex) => (
            <TableRow key={row}>
              {isMobile ? '' : <TableCell>{probLabel[rowIndex]}</TableCell>}
              {row.map((cell, colIndex) => (
                <TableCell
                  key={`${row},${cell}`}
                  style={matrixCellStyle(cell)}
                >
                  {renderCount(rowIndex, colIndex)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default RiskMatrix;
