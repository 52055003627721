import React from 'react';
import {
  Container,
} from '@material-ui/core';
import RiskMatrix from './RiskMatrix';
import RiskTable from './RiskTable';
import Title from './Title';

const Matrix = ({
  assessmentData,
  onEditAssessment,
  riskData,
  onAdd,
  onDelete,
  onEdit,
}) => (
  <Container>
    <Title
      title={assessmentData.name}
      description={assessmentData.description}
      createdOn={assessmentData.createdOn}
      updatedOn={assessmentData.updatedOn}
      state={assessmentData.state}
      onChange={onEditAssessment}
    />
    <RiskMatrix data={riskData} />
    <RiskTable
      data={riskData}
      onAdd={onAdd}
      onDelete={onDelete}
      onEdit={onEdit}
    />
  </Container>
);
export default Matrix;
