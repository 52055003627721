import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonBase,
  Container,
  TextField,
  Grid,
  Typography,
  Box,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Popover,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { Info } from '@material-ui/icons';
import Loading from './Assessment/Loading';

const Home = ({ onCreate, getLatest }) => {
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [showPopover, setShowPopover] = useState(false);
  const [popoverDetails, setPopoverDetails] = useState(0);
  const [latestAssessments, setLatestAssessments] = useState();
  const anchorEl = useRef(null);
  const history = useHistory();

  const init = async () => {
    try {
      const resp = await getLatest();
      setLatestAssessments(resp || []);
    } catch (err) {
      setLatestAssessments([]);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const renderPopover = () => (
    <Popover
      open={showPopover}
      anchorEl={anchorEl.current}
      onClose={() => {
        setShowPopover(false);
      }}
    >
      <Typography style={{ padding: '16px' }}>{popoverDetails}</Typography>
    </Popover>
  );
  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <Typography variant="h6">Create a New Assessment</Typography>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onCreate({ name, description })}
          >
            Create
          </Button>
        </Grid>
      </Grid>
      <Box mt={10}>
        {!latestAssessments ? (
          <Loading />
        ) : (
          latestAssessments.length !== 0 && (
            <>
              {renderPopover()}
              <Typography variant="h6">Latest Assessments</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {latestAssessments.map((a) => (
                    <TableRow key={a.slug}>
                      <TableCell>
                        <ButtonBase
                          onClick={() => history.push(`/a/${a.slug}`)}
                        >
                          <Typography variant="body2">
                            {a.name || 'Untitled Assessment'}
                          </Typography>
                        </ButtonBase>
                      </TableCell>
                      <TableCell>
                        <ButtonBase
                          onClick={() => history.push(`/a/${a.slug}`)}
                        >
                          <Typography variant="body2">
                            {a.description || 'No description'}
                          </Typography>
                        </ButtonBase>
                        <ButtonBase
                          onClick={(event) => {
                            anchorEl.current = event.target;
                            setShowPopover(true);
                            setPopoverDetails(
                              <>
                                Created on:
                                {' '}
                                {a.createdOn.format('L')}
                                {!!(a.createdOn - a.updatedOn)
                                                                    && (
                                                                    <>
                                                                      <br />
                                                                      Updated on:
                                                                      {' '}
                                                                      {a.updatedOn.format('L')}
                                                                    </>
                                                                    )}
                              </>,
                            );
                          }}
                        >
                          <Box sx={{ ml: 1, mt: 0.2 }}>
                            <Info fontSize="small" color="disabled" />
                          </Box>
                        </ButtonBase>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )
        )}
      </Box>
    </Container>
  );
};

export default Home;
